

































import { Vue, Component } from "vue-property-decorator";
import { $transaction } from "@/services/TransactionService";
import { $overlay } from "@/services/OverlayService";

@Component
export default class Success extends Vue {
  get overlay() {
    return $overlay.show;
  }

  get redirectUrl() {
    return $transaction.getState().transaction?.redirect_url;
  }

  get transactionId() {
    return $transaction.getState().transaction?.id;
  }

  async mounted() {
    $overlay.show = false;
    if (this.redirectUrl) {
      setTimeout(() => {
        window.location.href = (this.redirectUrl +
          "?transaction_id=" +
          this.transactionId) as string;
      }, 5000);
    }
  }
}
